import Vue from "vue";
import App from "./App.vue";

import VueRouter from "vue-router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/components/index"),
  },
  {
    path: "/result",
    component: () => import("@/components/result"),
  },
];
const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.path) {
    window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
  }
  next();
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
